import "animate.css";
import Loading from "../components/Loading/Loading.vue";
import axios from "axios";
import CONSTANTS from "../lib/Constants.js";
// import EventModel from "../lib/EventModel.js";

import moment from 'moment-timezone';
import { format, parseISO } from 'date-fns'

import Swal from "sweetalert2";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      events: [],
      dataVisible: false,
      props: [],
      showLoader: false,
      CONSTANTS,
      dialog: false,
      mode: "Event Create",
      id:'',
      ruleForm: {
        name: "",
        startDate: "",
        endDate: "",
        type: "fragrance",
        brand: "",
        video: "",
      },
      years: ['2025','2024','2023','2022'],

      numEvents:'',

      dateStart: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      menu1: false,

      timeStart: null,
      modaltime2: false,

      dateEnd: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      menu3: false,

      timeEnd: null,
      modaltime4: false,

      brand: null,
      items: [
        {text: 'Ysl', value:'ysl'},
        {text: 'Armani', value:'armani'}
      ],

      // urlAnterior: null,
    }
  },

  computed: {
    computedDateFormattedMomentjsStart() {
      return this.dateStart ? moment(this.dateStart).format('MM/DD/yyyy') : ''
    },
    computedDateFormattedMomentjsEnd() {
      return this.dateEnd ? moment(this.dateEnd).format('MM/DD/yyyy') : ''
    }
  },

  methods: {
    fetchEvents() {
      return new Promise((resolve, reject) => {
        this.showLoader = true;
        let url = this.CONSTANTS.API_MONGO_BASE_URL + "api/events?type=fragrance";

        let config = {
          headers: {
            "x-token-auth": localStorage.getItem("token"),
          },
        };

        axios
          .get(url, config)
          .then(async (result) => {
            // console.log(result);

            let eventTotal = 0;
            eventTotal = result.data.data.items.filter((el) => {
              if(el.active && el.type === 'fragrance'){
                return el;
              }
            });
            this.numEvents = eventTotal.length
            //console.log(eventTotal, 'eventTotal', this.numEvents);

            if (result.data) {

              result.data.data.items.forEach((el) => {
                if (el.active) {
                  this.events.push({
                    active: el.active,
                    brand: el.brand,
                    createdAt: el.createdAt,
                    endDate: el.endDate,
                    id: el.id,
                    intermission: el.intermission,
                    name: el.name,
                    rating: el.rating,
                    sandia: el.sandia,
                    startDate: el.startDate,
                    type: el.type,
                    video: el.video,
                    __v: el.__v,
                    _id: el._id,
                    year: moment(el.startDate).format('yyyy'),
                    attendees: el?.attendees || 0,
                    checkins: el?.checkins || 0
                  });
                }

                this.dataVisible = true;
                if (moment(el.startDate).format('yyyy') === '2024' && el.type === "fragrance") { this.dataVisible = false }
                /*
                this.events = result.data.data.items.filter((el) => {
                  if (el.active) {
                    return el;
                  }
                });
                */
              });

              await Promise.all(
                this.events.map(async (event) => {

                  console.log(event);

                  let attendees = {
                    confirmed: event.attendees || 0,
                    checkin: event.checkins || 0,
                    cancelled: 0,
                    registered: 0,
                    total: (event.attendees || 0) + (event.checkins || 0)
                  };

                  let confirmed = 0;
                  if (attendees.confirmed) {
                    confirmed = attendees.confirmed;
                  }
                  let checkin = 0;
                  if (attendees.checkin) {
                    checkin = attendees.checkin;
                  }
                  let totalAttendees = confirmed + checkin;
                  this.$set(event, "attendees", attendees);
                  this.$set(event, "totalAttendees", totalAttendees);
                })
              );
              // console.log(this.events);
              resolve();
            }
            reject();
          })
          .catch((error) => {
            // error.response.data.error.detail || "Error: Invalid credentials";
            alert("An error has ocurred");
            console.error(error);
            reject(error);
          });
        // .finally(()=>{
        //     this.showLoader = false;
        // });
      });
    },
    /*
    createTask() {
      this.startDate.props("type", "datetime-local");
    },
*/
    onDialogCreate(){
      this.mode = 'Event Create';
      this.dialog = true;
    },

    submitForm(formName) {
      this.loading = true;
      return this.saveForm(formName);
    },

    resetForm() {
      this.ruleForm.name = "";
      this.ruleForm.startDate = "";
      this.ruleForm.endDate = "";
      this.ruleForm.brand = "";
      this.ruleForm.video = "";
      this.dialog = false;
    },

    async saveForm() {

      new Swal ({
        customClass: {
          title: 'title-class-create'
        },
        title: 'Please Wait',
        allowOutsideClick: false
      });
      Swal.showLoading()

      let url = this.CONSTANTS.API_MONGO_BASE_URL + "api/events";
      let config = {
        headers: {
          "x-token-auth": localStorage.getItem("token"),
        },
      };

      axios.post(url, this.ruleForm, config).then((r) => {
        console.log(r);
        if (r) {
          this.dialog = false;
          this.resetForm();
          this.loading = false;
          this.fetchEvents();
          this.showLoader=false;
          return true;
        } else {
          return false;
        }
      });

      Swal.fire({
        customClass: {
          title: 'title-class-create',
          htmlContainer: 'text-class',
          confirmButton: 'confirmButton-class-create',
        },
        title: 'Saved.',
        text: 'Successfully registered event',
        confirmButtonText: "Ok",
        confirmButtonColor: '#1976d2',
        icon: 'success'
      });


    },
    getEvent(event) {
      let url = this.CONSTANTS.API_MONGO_BASE_URL + "api/events";
      let config = {
        headers: {
          "x-token-auth": localStorage.getItem("token"),
        },
      };

      axios.get(`${url}/${event._id}`,config).then((r) => {
        //console.log(r, 'load event');
        this.mode = "Edit Event";
        //this.ruleForm = r;
        this.dialog = true;

        this.ruleForm.name = r.data.data.name;

        this.dateStart = moment(r.data.data.startDate).format('yyyy-MM-DD').toString();
        this.timeStart = moment(r.data.data.startDate).tz('America/New_York').format('HH:mm');

        this.dateEnd = moment(r.data.data.endDate).format('yyyy-MM-DD').toString();
        this.timeEnd = moment(r.data.data.endDate).tz('America/New_York').format('HH:mm');

        this.ruleForm.brand = r.data.data.brand;
        this.ruleForm.video = r.data.data.video;
        //console.log(r.data.data.name);
      });
        this.id = `${event._id}`;
    },

    async submitUpdateForm(formName){

      let Date1 = this.dateStart+'T'+this.timeStart;
      let Date1Format = moment(Date1).tz('UTC').format('yyyy-MM-DD HH:mm')
      this.ruleForm.startDate = Date1Format;

      let Date2 = this.dateEnd+'T'+this.timeEnd;
      let Date2Format = moment(Date2).tz('UTC').format('yyyy-MM-DD HH:mm')
      this.ruleForm.endDate = Date2Format;

      const id = this.id;
      return this.saveUpdateForm(formName,id);
    },

    async saveUpdateForm(event,id) {
      new Swal ({
        customClass: {
          title: 'title-class'
        },
        title: 'Please Wait',
        allowOutsideClick: false
      });
      Swal.showLoading()

      let url = this.CONSTANTS.API_MONGO_BASE_URL + "api/events";
      let config = {
        headers: {
          "x-token-auth": localStorage.getItem("token"),
        },
      };

      axios
        .put(`${url}/${id}`,this.ruleForm,config)
        .then((r) => {
          // console.log(r);
          if (r) {
            this.resetForm();
            // this.loading = false;
            this.fetchEvents();
            this.showLoader=false;
            //return true;
          }
        });
      this.loading = false;

      Swal.fire({
        customClass: {
          title: 'title-class-update',
          htmlContainer: 'text-class',
          confirmButton: 'confirmButton-class-update',
        },
        title: 'Updated.',
        text: 'Event successfully updated',
        confirmButtonText: "Ok",
        confirmButtonColor: '#1976d2',
        icon: 'success'
      });
    },

    async deleteEvent(event) {

      const result = await Swal.fire({
        customClass: {
          title: 'title-class',
          htmlContainer: 'text-class',
          confirmButton: 'confirmButton-class',
          denyButton: 'dennyButton-class',
        },
        title: 'Confirm.',
        text: 'Are you sure you want to delete this event: ' + `${event.name}`,
        showDenyButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: '#1976d2',
        denyButtonText: "Not",
        denyButtonColor: '#F91414',

      })

      if (result.isConfirmed) {

        new Swal({
          customClass: {
            title: 'title-class'
          },
          title: "Espere por favor",
          allowOutsideClick: false,
        })
        Swal.showLoading()

        let url = this.CONSTANTS.API_MONGO_BASE_URL + "api/events";
        let config = {
          headers: {
            "x-token-auth": localStorage.getItem("token"),
          },
        };
        axios.delete(`${url}/${event._id}`, config).then((r) => {
          // console.log(r);
          if (r.status === 204) {
            this.fetchEvents();
            this.showLoader=false;
          } else {
            console.log("Se presento un Error")
            return false;
          }
        });

        Swal.fire({
          customClass: {
            title: 'title-class'
          },
          title: 'Successfully Removed',
          icon: 'success'
        });

      }
      //console.log(url, event, "delete", config);
    },

    navigateToEvent(event) {
      this.$router.push({ path: `/admin/event/${event._id}/registrations` });
      // this.$router.push({ path: `/admin/event/${event._id}/games` })
    },

    clickYear(data) {

      if ( data === 2025 ) {
       const result = this.events.filter((el) => moment(el.startDate).format('yyyy') === '2024' && el.type === "fragrance" );
        if (result.length == 0 ) {
          this.dataVisible = true;
        } else {
          this.dataVisible = false;
        }
      }
      if ( data === 2024 ) { this.dataVisible = false }
      if ( data === 2023 ) { this.dataVisible = false }
      if ( data === 2022 ) { this.dataVisible = false }

    }
  },
  async mounted() {
    try {
      this.showLoader = true;
      await this.fetchEvents();
    } catch (error) {
      console.error(error);
      debugger;
    } finally {
      this.showLoader = false;
    }
  },
};
