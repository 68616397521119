const currentEnv = ( () => {

    const origin = window.location.host.toLowerCase();
    let env = 'production';

    if (origin.indexOf('localhost') > -1) {
        env =  'dev'
    } else if(origin.indexOf('staging') > -1) {
        env =  'staging'
    }

    return env;

})();

const CONSTANTS = {
    CURRENT_ENV: currentEnv,
    API_USER_TOKEN: '85bdf869a799bc01',
    API_MONGO_BASE_URL: 'https://staging.yslarmaniseminars.com/', //'https://debrain-ysl-armani.herokuapp.com/',
    API_MONGO_BASE_CHAT_URL: 'https://staging.yslarmaniseminars.com/', //'https://debrain-ysl-armani.herokuapp.com/',
    SOCKET_SERVER_URL: 'https://staging.yslarmaniseminars.com/',
    SANDIA_API_BASE: 'https://api.yslarmaniseminars.com/',
    SANDIA_CAMPAIGN_ID: 104,
    SANDIA_EVENT_ID: 1722
};

console.log('ENVIRONMENT:::::', currentEnv);

if (currentEnv == 'production' ) {
    CONSTANTS.API_MONGO_BASE_URL = 'https://yslarmaniseminars.com/';
    CONSTANTS.API_MONGO_BASE_CHAT_URL = 'https://yslarmaniseminars.com/';
    CONSTANTS.SOCKET_SERVER_URL = 'https://yslarmaniseminars.com';
    CONSTANTS.SANDIA_API_BASE = 'https://api.yslarmaniseminars.com/';
}

if (currentEnv == 'dev' ) {
 
    CONSTANTS.API_MONGO_BASE_URL = 'https://staging.yslarmaniseminars.com/'; //'https://debrain-ysl-armani.herokuapp.com/',
    CONSTANTS.API_MONGO_BASE_CHAT_URL = 'https://staging.yslarmaniseminars.com/'; //'https://debrain-ysl-armani.herokuapp.com/',
    CONSTANTS.SOCKET_SERVER_URL = 'https://staging.yslarmaniseminars.com';
    CONSTANTS.SANDIA_API_BASE = 'https://api.yslarmaniseminars.com/';

    /*
    SOLO ENTORNO LOCAL
 
    CONSTANTS.API_MONGO_BASE_URL = 'http://localhost:8000/';
    CONSTANTS.API_MONGO_BASE_CHAT_URL = 'http://localhost:8000/';
    CONSTANTS.SOCKET_SERVER_URL = 'http://localhost:8000';
    CONSTANTS.SANDIA_API_BASE = 'https://api.yslarmaniseminars.com/';
    */
}


export default CONSTANTS;

