import EventModel from "../../lib/EventModel";
import axios from "axios";

import ChatModel from "../../lib/ChatModel";
import io from "socket.io-client";
import CONSTANTS from "../../lib/Constants";

import moment from "moment";

export default {
  props: ["event", "eventMongo"],
  data() {
    return {
      moment,
      CONSTANTS,
      queryString: "",
      questionStatus: "",
      favoriteStatus: "",
      statusFilter: ["all", "pending", "answered"],
      favoriteFilter: ["all", "highlighted"],
      awaitingSearch: false,
      options: {},
      headers: [
        { text: "Pinned", value: "pinned", sortable: false, width: '140px' },
        { text: "Created At", value: "createdAt", sortable: true },
        { text: "User", value: "user", sortable: true },
        { text: "Message", value: "message", sortable: true },
        { text: "Reply", value: "id", sortable: false, align: "center" },
        { text: "Message Reply", value: "replyTo", sortable: true },
        { text: "Action", value: "_id", sortable: true, align: "center" },
        // { text: 'Status', value: 'status', sortable: true, align: 'center' },
      ],
      eventQuestions: [],
      eventMessages: [],
      loading: false,
      refreshTimer: 15,
      interval: null,
      socket: null, 
      valuePinned: false,
      pinned: false,

      //io(CONSTANTS.SOCKET_SERVER_URL,{transports: ['websocket', 'polling']}),
      // timer:null


      userInfo: {
        id: localStorage.getItem('id'),
        user: localStorage.getItem('firstName'),
        email: localStorage.getItem('email'),
      },
      messageReply: {},
      dialogNewMessage: false,
      dialog: false,
      idMessageForm: '',
      messageForm: '',
      messageFormRules: [
        v => !!v || 'Message is required',
      ],
      checkboxForm: false,
      valid: true,
      validNewMessage: true,
      eventInfo: {},
      listReply: [],
      tableKey: 0
    };
  },

  computed: {
    totalQuestions() {
      return this.eventQuestions.length;
    },
    totalMessages() {
      return this.eventMessages.length;
    },
  },

  methods: {
    reloadTable() { 
      this.tableKey += 1; 
    },

    isReplyAllowed(item) { 
      return this.listReply.includes(item);
    },

    getChat() {
      // console.log('GET CHAT FUNCTION');
      let url_id = this.$route.params.event_id;
      const config = {
        headers: {
          'x-token-auth': localStorage.getItem('token')
        }
      };

      axios
        .get(this.CONSTANTS.API_MONGO_BASE_URL + 'api/chat?event=' + url_id, config)
        .then((r) => {

          const { data } = r || {};

          const { items } = data.data || {};

          // console.log('DATA OME', items);
          this.eventMessages = [];
          this.eventMessages = items;
          items.forEach((e) => {
            if(e.replyTo){
              this.listReply.push(e.replyTo._id);
            }
          });
          // console.log('LIST BLACK', this.listReply);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    dialogNew() {
      // console.log('ABRI EL DIALOG NEW MESSAGE');
      this.messageReply = {};
      this.dialogNewMessage = true;
    },

    dialogReply(item) {
      // console.log(item, 'ABRI EL DIALOG');
      this.messageReply = {};
      this.messageReply = { id: item._id, message: item.message }
      this.dialog = true;
    },

    validateNewMessage() {
      if (this.$refs.formNewMessage.validate()) {
        this.saveChat();
      }
      return false;
    },

    validate() {
      if (this.$refs.form.validate()) {
        this.saveChatReply();
      }
      return false;
    },

    cancelForm() {
      // console.log('CANCELAR');
      this.messageReply = {};
      this.idMessageForm = '',
        this.messageForm = '',
        this.dialog = false;
      this.dialogNewMessage = false;
    },

    saveChat() {

      try {
        this.socket.emit('chat:message', {
          event: this.event._id,
          user: 'Moderator', // `Moderator | ${this.userInfo.user}`,
          userId: this.userInfo.id,
          email: this.userInfo.email,
          message: this.messageForm,
          pinned: this.checkboxForm,
        });
        this.eventMessages= [];
        this.loadMessages();
      } catch (e) {
        console.log(e, 'error');
      } finally {
        this.dialogNewMessage = false;
        this.messageForm = '';
        this.checkboxForm = false;
      }

    },

    saveChatReply() {

      try {
        this.socket.emit('chat:message', {
          event: this.event._id,
          user: 'Moderator', // `Moderator | ${this.userInfo.user}`,
          userId: this.userInfo.id,
          email: this.userInfo.email,
          message: this.messageForm,
          pinned: this.checkboxForm,
          replyTo: this.messageReply.id
        });
        this.loadMessages();
        // console.log('EMITIDO')
      } catch (e) {
        console.log(e, 'error');
      } finally {
        this.dialog = false;
        this.messageForm = '';
        this.checkboxForm = false;
        this.messageReply = {};
      }

    },


    /***
     * OLD DEV
     */

    async loadQuestionData() {
      if (!this.event || !this.event._id) {
        return;
      }
      try {
        this.loading = true;
        let filter = {};
        if (this.queryString.trim().length > 0) {
          filter.query = this.queryString;
        }

        if (
          this.questionStatus.trim().length > 0 &&
          this.questionStatus !== "all"
        ) {
          filter.status = this.questionStatus;
        }

        if (this.favoriteStatus === "highlighted") {
          filter.highlighted = true;
        }

        this.eventQuestions = await EventModel.getEventQuestions(
          this.event,
          filter
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    pinnedMessage(data) {
      if ( data.pinned ) {
        this.socket.emit('pin:status', { event: this.event._id, message: data._id, status: 'show' });
        this.reloadTable();
      } else if ( !data.pinned ) {
        this.socket.emit('pin:status', { event: this.event._id, message: data._id, status: 'hide' });
      }
    },

    hidePinned(data) {
      // console.log('SE PINEO OME EL HIDE', data);
      if (data.pinned) {
        this.socket.emit('pin:status', { event: this.event._id, message: data._id, status: 'hide' });
      }
    },

    async updateStatus(item) {
      //alert(JSON.stringify(item));
      let newStatus = item.status === "answered" ? "pending" : "answered";
      item.status = newStatus;
      await EventModel.updateQuestionStatus(item);

      // alert('emitting');
      if (!this.loading) {
        console.log(
          this.socket.emit("message:update", {
            _id: item._id,
            event: this.event._id,
            status: newStatus,
          })
        );
      }
    },

    async updateFavoriteStatus(item) {
      //alert(JSON.stringify(item));
      let newStatus = item.favorite ? false : true;
      item.favorite = newStatus;

      await EventModel.updateQuestionFavoriteStatus(item);

      if (!this.loading) {
        console.log(
          this.socket.emit("message:update", {
            _id: item._id,
            event: this.event._id,
            favorite: newStatus,
          })
        );
      }
    },

    registerEvents() {
      if (!this.socket) {
        console.warn("NO SOCKET YET!!!");
        return;
      }

      this.socket.on("connect", () => {
        console.log("connected");
        console.log(this.eventMongo);
      });

      this.socket.emit("chat:signin", {
        type: "signin",
        event: `lancome-admin-${this.eventMongo._id}`,
        id: 1,
      });

      this.socket.on("chat:room", () => {
        console.log("Connected to ");
        console.log(`lancome-admin-${this.eventMongo._id}`);
      });
      // Reconnect
      this.socket.on("reconnect", () => {
        this.socket.emit("chat:signin", {
          type: "reconnect",
          event: `lancome-admin-${this.eventMongo._id}`,
          id: 1,
        });
      });

      this.socket.on("message:received", async () => {
        this.loading = true;
        await this.loadMessages();
        this.loading = false;
      });

      this.socket.on("message:updated", async (data) => {
        try {
          this.loading = true;

          if (!Array.isArray(this.eventQuestions)) {
            this.eventQuestions = [];
          }
          let questions = [...this.eventQuestions];
          let position = questions.findIndex((el) => el._id === data._id);
          if (position > -1) {
            questions[position] = data;
            this.$set(questions, position, data);
          }
          await this.loadMessages();
        } finally {
          this.loading = false;
        }
      });

      this.socket.on('pin:updated', (data) => {
        // console.log(data, 'papi llego');

        const {
          _id
        } = data;

        const chats = [];

        this.eventMessages.forEach( (c) => {
          if (c._id === _id) {
            chats.push(data);
          } else {
            chats.push(c);
          }
        });
        this.eventMessages  = [];
        // empty games but has incoming game?
        if (chats.length === 0) {
          chats.push(data);
        }
        
        this.eventMessages  = [...chats];
        setTimeout(() => {
          this.loadMessages();
        }, 3000);

      });

      this.socket.on('chat:received', (data) => {
        this.loadMessages();
        console.log('Received', data);
      });
    },


    async loadMessages() {
      // eventMessages
      if (!this.event || !this.event._id) {
        return;
      }
      try {
        this.loading = true;
        clearInterval(this.tick);
        this.refreshTimer = 2;
        let filter = {};
        if (this.queryString.trim().length > 0) {
          filter.query = this.queryString.trim();
        }
        this.getChat();
        /*
        this.eventMessages = await ChatModel.getEventChatMessages(
          this.event._id,
          filter
        );*/
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
        
        // console.log(this.eventMessages, 'hola ome' );
        // if (!this.interval) this.interval = setInterval(this.tick, 1000);
      }
    },

    async deleteChatMessage(message) {
      // console.log('NTRO A ELIMINAR');
      if (!message) {
        return;
      }
      try {
        var r = confirm(
          "This action can not be undone, are you sure you wan't to delete this message?"
        );
        if (r == true) {
          this.loading = true;
          await ChatModel.deleteEventChatMessages(message._id);
          this.socket.emit("chat:remove", {
            _id: message._id,
            event: this.eventMongo._id,
          });
          await this.loadMessages();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    tick() {
      let remainingTime = this.refreshTimer - 1;
      if (remainingTime < 1) {
        remainingTime = 2;
        this.loadMessages();
      }
      // else{
      //     remainingTime -= 1;
      // }
      this.refreshTimer = remainingTime;
      // console.log('tick', this.refreshTimer, remainingTime);
    },

    exportChat() {

      let url_id = this.$route.params.event_id;

      axios
        .request({
          url: this.CONSTANTS.API_MONGO_BASE_URL + 'api/reports/chats?event=' + url_id,
          method: "GET",
          headers: { "x-token-auth": localStorage.getItem("token") },
          responseType: "blob",
        })
        .then(({ data }) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", "Chats.xlsx"); //any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((error) => {
          console.log(error);
        });
    },

  },

  watch: {
    queryString() {
      // console.log('Hola Buenasmijo');
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.currentPage = 1;
          //this.loadQuestionData();
          this.loadMessages();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },

    questionStatus(newVal) {
      if (newVal === "all") {
        this.questionStatus = "";
      }
      this.loadQuestionData();
    },

    favoriteStatus(newVal) {
      if (newVal === "all") {
        this.favoriteStatus = "";
      }
      this.loadQuestionData();
    },

    options: {
      handler(newVal) {
        // console.log(this.options);
        // debugger;
        this.order_by =
          newVal && newVal.sortBy && newVal.sortBy[0]
            ? newVal.sortBy[0]
            : "email";
        this.order_dir =
          newVal && newVal.sortDesc && newVal.sortDesc[0] ? "DESC" : "ASC";
        //this.loadQuestionData();
        this.loadMessages();
      },
      deep: true,
    },

    event() {
      if (this.event) {
        this.loadMessages();
      }
    },

    eventMongo(newVal) {
      if (newVal && newVal._id) {
        this.socket = io(CONSTANTS.SOCKET_SERVER_URL, {
          secure: true,
          transports: ["websocket", "polling"],
        });
        this.registerEvents();
      }
    },
  },

  beforeMount() {
    this.registerEvents();
  },

  async mounted() {
    // this.interval = setInterval(this.tick, 5000);
    await this.loadMessages();
    this.socket = io(CONSTANTS.SOCKET_SERVER_URL);

    /*
    if (this.eventMongo && this.eventMongo._id) {
      
      this.socket = io(CONSTANTS.SOCKET_SERVER_URL, {
        secure: true,
        transports: ["websocket", "polling"],
      });
      
      this.registerEvents();
    } else {
      // await this.fetchEventInfo();
    }
    */
    // this.timer = setTimeout(()=>{ this.show = false; console.log('Timer');}, 5000);
    //this.totalResults = this.attendees.length;
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
};
